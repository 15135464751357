import React, { useRef } from 'react';
import $ from 'jquery';
import emailjs from '@emailjs/browser';
import { IoWarningSharp } from 'react-icons/io5';
import '../css/Contact.css';

function Contact(props) {
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    if (
      $('.user_name').val() === '' ||
      $('.user_email').val() === '' ||
      $('.message').val() === ''
    ) {
      if ($('.user_name').val() === '') {
        $('.name.error').removeClass('err-hidden');
      }

      if ($('.user_email').val() === '') {
        $('.email.error').removeClass('err-hidden');
      }

      if ($('.message').val() === '') {
        $('.message.error').removeClass('err-hidden');
      }
    } else {
      $('.name.error').addClass('err-hidden');
      $('.email.error').addClass('err-hidden');
      $('.message.error').addClass('err-hidden');
      emailjs
        .sendForm(
          'service_qybifle',
          'template_8xw7hvk',
          form.current,
          'user_GtSL5lIk3N7YBMUKI28ro'
        )
        .then(
          (result) => {
            console.log(result.text);
          },
          (error) => {
            console.log(error.text);
          }
        );
    }
  };
  return (
    <div className='form-wrapper'>
      <form ref={form} onSubmit={sendEmail} className='form'>
        <div className='form-item-holder'>
          <label>Name</label>
          <input type='text' name='user_name' className='user_name form-item' />
        </div>
        <div className='form-item-holder'>
          <label>Email</label>
          <input
            type='email'
            name='user_email'
            className='user_email form-item'
          />
        </div>
        <div className='form-item-holder'>
          <label>Message</label>
          <textarea name='message' className='message form-item' />
        </div>
        <input type='submit' value='Send' className='form-submit'/>
      </form>
      <div className='err-wrapper'>
        <div className='err-cont'>
          <div className='name error err-hidden'>
            <IoWarningSharp />
            <span>Please enter a name before submitting your message.</span>
          </div>
          <div className='email error err-hidden'>
            <IoWarningSharp />
            <span>Please enter an email before submitting your message.</span>
          </div>
          <div className='message error err-hidden'>
            <IoWarningSharp />
            <span>Please enter a message before submitting your email.</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
