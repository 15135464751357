import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import VidWork from './pages/VidWork';
import './css/App.css';

function App() {
  return (
    <div>
      <VidWork />
    </div>
  );
}

export default App;
